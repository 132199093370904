import axios from "axios";

const sign = require("jwt-encode");
const secret =
    "hbGciOiJxNTE2MjM5MIUzI1NiIsInR5cCI6ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaNTY3fyWF0IjoDIew56y";
const data = {
    name: "Stephanos",
};
const jwt = sign(data, secret);

let API_ROUTE;
const STAGE = process.env.NODE_ENV;

let url;
if (STAGE === "development") {
    url = "http://localhost/stephanos/admin/public/";
    //API_ROUTE = "http://localhost:3000/api/";
    API_ROUTE = "https://api.stephanos.cz/api/";
} else {
    url = "https://admin.stephanos.cz/public/";
    API_ROUTE = "https://api.stephanos.cz/api/";
}

let logged;
if (process.env.NODE_ENV === "development") {
    logged = "http://localhost/stephanos/admin/api/";
} else {
    logged = "https://admin.stephanos.cz/api/";
}

export const loggedUrl = logged;
export const apiUrl = url;

const axiosInstance = axios.create({
    // withCredentials: true,
    baseURL: url,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + jwt,
    },
});

const protectedAxios = () => {
    const userToken = JSON.parse(localStorage.getItem("token"));

    return axios.create({
        // withCredentials: true,
        baseURL: logged,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + userToken?.token,
        },
    });
};

export const axiosProtected = protectedAxios;
export default axiosInstance;
export const CONFIG_APP_API_ROUTE = API_ROUTE;
